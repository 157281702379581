import React from "react";
// import { Link } from "react-router-dom";
import About from "../../assets/img/bg/an-img-02.png";
import About1 from "../../assets/img/features/about_img_02.png";

function Terms() {
  return (
    <>
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
        <div className="animations-02">
          <img src={About} alt="contact-bg-an-02" />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            {/* <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={About1} alt="img" />
              </div>
            </div> */}
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content  wow fadeInRight  animated pl-30"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>About Us</h5>
                  <h2>Terms & Conditions</h2>
                  <h5>
                    Thank you for choosing us.
                    <br />
                    Please find below the sales conditions related to your
                    booking:
                  </h5>
                </div>
                <h4>Rooms Reservation:</h4>
                <div className="about-content3 mt-30">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul className="mb-30">
                        <li>
                          {" "}
                          We advise you to book rooms for better deals through
                          our hotel website (seed-hotel.com) For confirmed
                          guaranteed reservation you can simply do it through
                          email or telephone call or WhatsApp message. Or
                          through Quick web check-in option is available at
                          “BOOK NOW”.{" "}
                        </li>
                        <li>
                          {" "}
                          Check-in time is from 12.00 noon & check-out until
                          11.00 AM.{" "}
                        </li>
                        <li>
                          {" "}
                          Extra Person/Bed $20 per night. Children aged 8 years
                          and older are charged. For children below 8 years
                          sharing with adults is complimentary with existing
                          bedding.{" "}
                        </li>
                        <li>
                          {" "}
                          We accept Visa, Master card (credit or debit card) and
                          various Indian net banking channels or through online
                          air pay secured channel. All reservation by email or
                          phone calls needs to be guaranteed by credit card
                          details. We will not charge from your credit card. A
                          one-dollar transaction will conduct to check the
                          authenticity of your card – which then will be
                          refunded immediately. It will reflect in your bank
                          statement.{" "}
                        </li>
                        <li>
                          {" "}
                          Earlier check-in is subject to availability. For
                          guaranteed early check-in, reservation needs to be
                          made starting from the previous night.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h4>Cancellation policy for Hotel Pearl Palace.</h4>
                <div className="about-content3 mt-30">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul className="mb-30">
                        <li>
                          {" "}
                          SEED Hotel operates on a free cancellation policy with
                          no extra charges.{" "}
                        </li>
                        <li>
                          {" "}
                          In case of no show with no communication about
                          cancellation full booking amount we be charged. <br />
                          Guaranteed reservation room will be held for the guest
                          till next day 6:00 am of arrival date, after which it
                          will be treated as no show booking.{" "}
                        </li>
                        <li>
                          {" "}
                          In case of dates change existing booking needs to be
                          cancelled, see cancellation policy. Reservation of
                          rooms on new dates will be subject to availability.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h4>Terms & Conditions policy for SEED Hotel.</h4>
                <div className="about-content3 mt-30">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul className="mb-30">
                        <li>
                          {" "}
                          As per government regulations, we CAN’T check in any
                          foreign guests without a valid passport and valid
                          travel visa. All guests MUST present one of the
                          following: Passport copy, valid driving license, Voter
                          ID card or UN ID Card.{" "}
                        </li>
                        <li>
                          {" "}
                          The hotel reserves its right of admission.
                          Accommodation can be denied in case incorrect
                          information is provided in the reservation form (e.g.
                          intentionally incorrect names for impersonation).{" "}
                        </li>
                        <li>
                          {" "}
                          It is unlikely that we will change or cancel your
                          booking once it is confirmed. If we do make changes
                          then we will inform you as soon as we reasonably can.
                          We reserve the right to make changes that are not
                          significant and to correct errors in your booking
                          details at any time without being liable to you.{" "}
                        </li>
                        <li>
                          {" "}
                          Sometimes an unintentional mistake of publishing
                          inaccurate information on the site may occur (e.g.,
                          the price, description or availability). As always
                          contact us if you have any problems in your travels or
                          would like to change or rearrange your reservation.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h4>Limitation of Liability.</h4>
                <div className="about-content3 mt-30">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul className="mb-30">
                        <li>
                          {" "}
                          Any refund will be returned only in to the same Credit
                          /Debit Card account / bank account /or any other
                          method that may have been used to send the advance. It
                          may take up to 14 working days to refund the amount
                          from the date of cancellation email. We do not accept
                          liability where any form of loss or damage whether by
                          reason of death, injury, illness or otherwise. Any
                          such loss caused by unusual or unforeseeable
                          circumstances beyond our control the consequences of
                          which could not have been avoided even if all due care
                          had been exercised or where any loss or damage is due
                          to an event which we could not have either foreseen or
                          forestalled. The property will not be in any breach of
                          this agreement or in any way liable, if it is
                          prevented from complying with the supply of
                          accommodation and other related services by reason of
                          Act of God, Act of Public Enemy, War, Earthquake,
                          Riot, Fire, Storm, Flood, Explosion, Compliance with
                          any Law of Government Restraint Order, Rule
                          Regulations, Strikes, Lock Outs or any other cause not
                          reasonably within the control of the hotel. SEED Hotel
                          does not accept any liability for any failure by hotel
                          to comply with these conditions where such failure is
                          due to circumstances beyond its reasonable control.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h4>Your Responsibility.</h4>
                <div className="about-content3 mt-30">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul className="mb-30">
                        <li>
                          {" "}
                          Legitimate reservations: <br /> You agree that you
                          will make only legitimate reservations in good faith
                          for use by you and your invited guests only, and not
                          for other purposes, including without limitation,
                          reselling, impermissibly assigning or posting on third
                          party web sites or making speculative, false or
                          fraudulent reservations, or any reservation in
                          anticipation of demand. While it is true that we serve
                          guests from all over the globe, we must maintain
                          strict local Ugandan rules about uunderage children
                          staying together. We apologize for this measure but
                          find that it is in keeping with the highest standards
                          of conduct to do so. If this is your intent, please
                          don’t book at our hotel room by email or any online
                          sites. If you book online, we will cancel your
                          reservation on arrival. Thank you for your
                          understanding.{" "}
                        </li>
                        <li>
                          Insurance:
                          <br />
                          For your own protection, you and all members of your
                          party must have suitable insurance (including
                          insurance to cover the costs of cancellation of your
                          holiday and the costs of assisting you if you or your
                          party has an accident or becomes ill) for the full
                          period of your holiday.
                        </li>
                        <li>
                          Passport and Identity proof:
                          <br />
                          You must ensure that you have a valid passport and any
                          necessary visas for foreign guests and for East
                          African nationalities, in absence of Passport, ID
                          proof is required for Check-in. Please carry your
                          relevant papers with you.
                        </li>
                        <li>
                          Governing law:
                          <br />
                          Substantive laws of the Republic of Uganda.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h5>
                  Kindest Regards,
                  <br />
                  Management SEED Hotel
                </h5>
                <p>
                  {" "}
                  I hereby state that I have fully read all the terms and
                  conditions other information stated herein, understood the
                  same and accept the same as binding on me when I make my room
                  booking.
                </p>
                <p>
                  We wish you a fulfilling and pleasant stay at SEED Hotel
                  Entebbe.
                </p>
                <h6>
                  Best Wishes
                  <br />
                  Management
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms;
