import React from "react";
// import Bredcom from "../../Bradcom/Main";
import Luxury from "../../Menhome/Luxury";
import Book from "../../Menhome/Book";
import Take from "../../Menhome/Take";
import Rooms from "../../Menhome/Rooms";

const Main = () => {
  return (
    <>
      <main>
        {/* <Bredcom title={"Rooms"} subtitle={"Home"} newtitle={"Rooms"} /> */}
        <Rooms />
        <Luxury />
        <Book />
        <Take />
      </main>
    </>
  );
};

export default Main;
