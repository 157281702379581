import React from "react";
import { Link } from "react-router-dom";
import About from "../../assets/img/bg/an-img-02.png";
import About1 from "../../assets/img/features/about_img_02.png";

function Policies() {
  return (
    <>
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
        <div className="animations-02">
          <img src={About} alt="contact-bg-an-02" />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            {/* <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={About1} alt="img" />
              </div>
            </div> */}
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content  wow fadeInRight  animated pl-30"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>About Us</h5>
                  <h2>Policies</h2>
                  <h5>
                    Thank you for choosing us.
                    <br />
                    Please find below the sales policies related to your
                    booking:
                  </h5>
                </div>
                <h4>Privacy Policy:</h4>
                <div className="about-content3 mt-30">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul className="mb-30">
                        <li>
                          {" "}
                          SEED Hotel respects your right to privacy. Any
                          personal information that you share with us, like your
                          name, date of birth, address, marital status,
                          telephone number, credit/debit card particulars and
                          the like, shall be entitled to privacy and kept
                          confidential. SEED Hotel assures you that your
                          personal information shall not be permitted to be
                          used/disclosed, save for the purpose of doing the
                          intended business with you, or if required to be
                          disclosed under the due process of law. If you have
                          any questions or concerns regarding your privacy
                          issues, please do not hesitate to contact us on a mail
                          info@seed-hotel.com .{" "}
                        </li>
                        <li>
                          {" "}
                          Check-in time is from 12.00 noon & check-out until
                          11.00 AM.{" "}
                        </li>
                        <li>
                          {" "}
                          Extra Person/Bed $20 per night. Children aged 8 years
                          and older are charged. For children below 8 years
                          sharing with adults is complimentary with existing
                          bedding.{" "}
                        </li>
                        <li>
                          {" "}
                          We accept Visa, Master card (credit or debit card) and
                          various Indian net banking channels or through online
                          air pay secured channel. All reservation by email or
                          phone calls needs to be guaranteed by credit card
                          details. We will not charge from your credit card. A
                          one-dollar transaction will conduct to check the
                          authenticity of your card – which then will be
                          refunded immediately. It will reflect in your bank
                          statement.{" "}
                        </li>
                        <li>
                          {" "}
                          Earlier check-in is subject to availability. For
                          guaranteed early check-in, reservation needs to be
                          made starting from the previous night.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h4>Restaurant Policy:</h4>
                <div className="about-content3 mt-30">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul className="mb-30">
                        <li>
                          {" "}
                          The SEED Restaurant is accessed through our hotel
                          lobby; thus, we must be ever vigilant to ensure the
                          comfort of our staying guests. As such, we request
                          “local resident families” to make a prior booking at
                          the restaurant (we might ask for ID for visiting our
                          restaurant). However, our gardens provide an excellent
                          space for dinner and other related services.{" "}
                        </li>
                        <li>
                          {" "}
                          We appreciate that you have thought of us as a
                          celebration location, please appreciate that we have
                          traveling families & children under our roof. Please
                          keep silence in lobby, corridors, and staircases
                          especially during night-time as hotels guest might be
                          resting.{" "}
                        </li>
                        <li className="green">
                          {" "}
                          Our parking is limited and reserved for tourists
                          visiting our restaurant and for our hotel guests.
                          Please use courtesy when parking in the residential
                          area surrounding our restaurant.{" "}
                        </li>
                        <li>
                          At SEED Hotel we do not discriminate our guests based
                          on religion, colour, creed, nationality, sex or race.
                          In our constant endeavour for guest safety and
                          especially the safety of our female guests we take all
                          possible steps to ensure their safety. We also are a
                          drug free hotel and do not allow the usage of any kind
                          of drugs or narcotics in our hotel or allow anyone
                          under the influence of alcohol to enter our hotel.
                          Furthermore, we strictly bar solicitation at our hotel
                          or our restaurant. We thank you for your kind
                          attention to our hotel policy and hope for your best
                          cooperation in this matter. If you may have any
                          additional questions that the front office staff are
                          unable to answer, please feel free to contact the
                          company Secretary directly – Ms Joseline Nabatanzi
                          Clare on +256 701 324588 or the local police station
                          regarding state and national policies for female and
                          hotel guest safety .
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h5>
                  Kindest Regards,
                  <br />
                  Management SEED Hotel
                </h5>
                <p>
                  {" "}
                  I hereby state that I have fully read all the terms and
                  conditions other information stated herein, understood the
                  same and accept the same as binding on me when I make my room
                  booking.
                </p>
                <p>
                  We wish you a fulfilling and pleasant stay at SEED Hotel
                  Entebbe.
                </p>
                <h6>
                  Best Wishes
                  <br />
                  Management
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Policies;
