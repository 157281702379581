import React from "react";
import First from "../Mencontact/First";

const Main = () => {
  return (
    <>
      <main>
        <First />
      </main>
    </>
  );
};

export default Main;
