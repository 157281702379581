import React from "react";
import { Link } from "react-router-dom";
import Hotel from "../../assets/img/bg/an-img-01.png";
import Hotel1 from "../../assets/img/icon/fe-icon01.png";
import Hotel3 from "../../assets/img/icon/fe-icon05.png";
import Hotel5 from "../../assets/img/icon/fe-icon07.png";

function Explore() {
  return (
    <>
      <section
        id="service-details2"
        className="pt-120 pb-90 p-relative"
        style={{ background: "#f7f5f1" }}
      >
        <div className="animations-01">
          <img src={Hotel} alt="an-img-01" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title center-align mb-50 text-center">
                <h5>Explore</h5>
                <h2>Some of our services</h2>
                <p>
                  Showcasing our diverse and top-notch services tailored for an
                  unparalleled guest experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-08-item mb-30">
                <div className="services-icon2">
                  <img src={Hotel1} alt="img" />
                </div>
                <div className="services-08-thumb">
                  <img src={Hotel1} alt="img" />
                </div>
                <div className="services-08-content">
                  <h3>Quality Rooms</h3>
                  <p>
                    Exquisite Accommodations: Immerse yourself in comfort and
                    style with our quality rooms, meticulously designed to
                    provide a superior stay that exceeds your expectations.
                  </p>
                  {/* <Link to="/service-details">Read More <i className="fal fa-long-arrow-right"></i></Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-08-item mb-30">
                <div className="services-icon2">
                  <img src={Hotel3} alt="img" />
                </div>
                <div className="services-08-thumb">
                  <img src={Hotel3} alt="img" />
                </div>
                <div className="services-08-content">
                  <h3>Best Accommodation</h3>
                  <p>
                    Superlative Stay: Experience unparalleled comfort and
                    exceptional service in our best-in-class accommodation,
                    where every detail is thoughtfully curated for your utmost
                    satisfaction and enjoyment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-08-item mb-30">
                <div className="services-icon2">
                  <img src={Hotel5} alt="img" />
                </div>
                <div className="services-08-thumb">
                  <img src={Hotel5} alt="img" />
                </div>
                <div className="services-08-content">
                  <h3>Dine & Celebrate with us</h3>
                  <p>
                    Savor exquisite culinary delights and premium beverages at
                    our "Dine & Celebrate" experience, where every dish and sip
                    is crafted to elevate your dining pleasure to new heights..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Explore;
