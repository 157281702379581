import React from "react";
import { Link } from "react-router-dom";
import Resort from "../../assets/img/bg/an-img-02.png";
import Resort1 from "../../assets/img/features/feature.png";

function Luxury() {
  return (
    <>
      <section
        className="feature-area2 p-relative fix"
        style={{ background: "#f7f5f1" }}
      >
        <div className="animations-02">
          <img src={Resort} alt="contact-bg-an-05" />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
              <div className="feature-img">
                <img src={Resort1} alt="img" className="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="feature-content s-about-content">
                <div className="feature-title pb-20">
                  <h5>More about us</h5>
                  <h2>Our facilities</h2>
                </div>
                <p>
                  WE provide 24hr support for our guests,and below is the list
                  of our facilities ,and services offered to our guests
                </p>
                <div className="col-md-12">
                  <ul className="green mb-30">
                    <li> Free parking space. </li>
                    <li> Free WiFi </li>
                    <li> Gardens for relaxation. </li>
                    <li> Restaurant </li>
                    <li> Accommodation </li>
                    <li> Breakfast </li>
                  </ul>
                </div>
                {/* <div className="slider-btn mt-15">                                          
                                    <Link to="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Luxury;
