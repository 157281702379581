import React, { useState } from "react";
import BGImg from "../../assets/img/slider/slider_bg.png";
import BGImg1 from "../../assets/img/slider/slider_bg_01.jpg";
import Slider from "react-slick";
import { Link } from "react-router-dom";

function Sliderhome() {
  const [animation, setanimation] = useState();

  const home = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section id="home" className="slider-area fix p-relative">
        <Slider className="slider-active" {...home}>
          <div className="col-xl-2">
            <div
              className="single-slider slider-bg d-flex align-items-center"
              style={{ backgroundImage: `url(${BGImg})` }}
            >
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-7 col-md-7">
                    <div className="slider-content s-slider-content mt-80 text-center">
                      <h2
                        className={`${animation && "animation" && "fadeInUp"} `}
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{ animationDelay: "0.4s" }}
                      >
                        Enjoy A Comfortable Experience
                      </h2>
                      <p data-animation="fadeInUp" data-delay=".6s">
                        Indulge in unparalleled comfort and relaxation, savoring
                        a delightful experience designed to exceed your
                        expectations and make your stay truly memorable.
                      </p>

                      <div className="slider-btn mt-30 mb-105">
                        <Link
                          to="/about"
                          className="btn ss-btn active mr-15"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Discover More{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2">
            <div
              className="single-slider slider-bg d-flex align-items-center"
              style={{
                backgroundImage: `url(${BGImg1})`,
                background_size: "cover",
              }}
            >
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-7 col-md-7">
                    <div className="slider-content s-slider-content mt-80 text-center">
                      <h2
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{ animationDelay: "0.4s" }}
                      >
                        Connect with Us
                      </h2>
                      <p data-animation="fadeInUp" data-delay=".6s">
                        Reach out effortlessly for inquiries, reservations, or
                        assistance. Our dedicated team is here to ensure your
                        experience exceeds expectations. Get in touch today!
                      </p>

                      <div className="slider-btn mt-30 mb-105">
                        <Link
                          to="/contact"
                          className="btn ss-btn active mr-15"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Contact Us{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>
</>
  );
}

export default Sliderhome;
