import React from "react";
import BGImg from "../../assets/img/bg/bdrc-bg.jpg";
import Most from "../Menhome/Most";
import Luxury from "../Menhome/Luxury";

const Main = () => {
  return (
    <>
      <main>
        <Most />
        <Luxury />
      </main>
    </>
  );
};

export default Main;
