import React from 'react'
import { Link } from 'react-router-dom'
import Hotel from "../../assets/img/bg/an-img-01.png"
import pricing1 from "../../assets/img/bg/an-img-02.png"

function Prices() {
  return (
    <>
        <section id="pricing" className="pricing-area pt-120 pb-60 fix p-relative">
                <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                <div className="animations-02"><img src={pricing1} alt="contact-bg-an-01"/></div>
                <div className="container"> 
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-4 col-md-12">
                            <div className="section-title mb-20">
                                <h5>There is more</h5>
                                <h2>Extra Services</h2>                               
                            </div>
                            <p>Indulge in an elevated experience with our packages, where each offering is complemented by exclusive extra services. Enhance your stay with these thoughtful additions, tailored to elevate and enrich your overall enjoyment and satisfaction.</p>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head">  
                                    <h3>Laundry Services</h3>    
                                    <p>Revitalize Your Wardrobe: Exceptional Laundry Services Tailored for You</p>
                                    <hr/>
                                </div>
                                <div className="pricing-body mt-20 mb-30 text-left">
                                    <ul>
                                        <li>Careful Handling</li>
                                        <li>Quality Detergents</li>
                                        <li>Timely Turnaround</li>                                           
                                    </ul>
                                </div>  
                            </div>
                        </div>
                         <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head">  
                                    <h3>Airport Pickups</h3>    
                                    <p>Effortless Transfers: Complimentary Airport Pickups</p>
                                    <hr/>
                                </div>
                                <div className="pricing-body mt-20 mb-30 text-left">
                                    <ul>
                                        <li>Convenient Transportation</li>
                                        <li>Personalized Service</li>
                                        <li>Local Insight and Assistance</li> 
                                    </ul>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </>
  )
}

export default Prices