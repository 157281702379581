import React, { useState } from "react";
import service1 from "../../assets/img/gallery/room-img01.jpg";
import service2 from "../../assets/img/gallery/room-img02.jpg";
import { Link } from "react-router-dom";
import FsLightbox from "fslightbox-react";

function Rooms() {
  const [toggler, setToggler] = useState(false);
  const [activeImage, setActiveImage] = useState(1);
  const [images, setImages] = useState([
    "assets/img/gallery/room-img01.jpg",
    "assets/img/gallery/room-img02.jpg",
    "assets/img/gallery/room-img03.jpg",
    "assets/img/gallery/room-img04.jpg",
    "assets/img/gallery/room-img05.jpg",
    "assets/img/gallery/room-img06.jpg",
  ]);

  return (
    <>
      <section id="services" className="services-area pt-113 pb-150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="section-title center-align mb-50 text-center">
                <h5>Explore More</h5>
                <h2>Our Rooms</h2>
                <p>
                  Our exquisite rooms offer unparalleled comfort with king-size
                  beds, modern amenities, and personalized touches for
                  perfection
                </p>
              </div>
            </div>
          </div>
          <div className="row services-active">
            <div className="col-xl-6 col-md-6">
              <div className="single-services mb-30">
                <div className="services-thumb">
                  <Link
                    to="#"
                    className="gallery-link popup-image"
                    onClick={() => {
                      setToggler(!toggler);
                      setActiveImage(1);
                    }}
                  >
                    <img src={service1} alt="img" />
                  </Link>
                </div>
                <div className="services-content">
                  <div className="day-book">
                    <ul>
                      <li>$50/Night</li>
                      <li>
                        <Link to="/reservation">Book Now</Link>
                      </li>
                    </ul>
                  </div>
                  <h4>
                    <Link to="/reservation">Standard Room</Link>
                  </h4>
                  <p>
                    <i>
                      <b>Elevate Your Stay</b>
                    </i>
                    : Experience unparalleled comfort in our rooms furnished
                    with luxurious king-size beds. Refresh in the well-equipped
                    bathroom featuring a shower and water heater. Stay connected
                    with complimentary WiFi, unwind with a TV, and conveniently
                    charge your devices with power sockets. Effortlessly
                    communicate with a direct dial telephone for added
                    convenience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-6">
              <div className="single-services mb-30">
                <div className="services-thumb">
                  <Link
                    to="#"
                    className="gallery-link popup-image"
                    onClick={() => {
                      setToggler(!toggler);
                      setActiveImage(2);
                    }}
                  >
                    <img src={service2} alt="img" />
                  </Link>
                </div>
                <div className="services-content">
                  <div className="day-book">
                    <ul>
                      <li>$60/Night</li>
                      <li>
                        <Link to="/reservation">Book Now</Link>
                      </li>
                    </ul>
                  </div>
                  <h4>
                    <Link to="/reservation">Deluxe Room</Link>
                  </h4>
                  <p>
                    <i>
                      <b>Tailored for Comfort</b>
                    </i>
                    : Our double occupancy room features a spacious layout with
                    two inviting beds, a well-appointed bathroom complete with a
                    shower and water heater for your relaxation. Stay connected
                    with complimentary WiFi Internet, and conveniently
                    communicate with a direct telephone at your fingertips.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FsLightbox toggler={toggler} sources={images} slide={activeImage} />
    </>
  );
}

export default Rooms;
