import { Link } from "react-router-dom";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function First() {
  const [activeImage, setActiveImage] = useState(1);

  const [images, setImages] = useState([
    "assets/img/gallery/protfolio-img02.jpg",
    "assets/img/gallery/protfolio-img03.jpg",
    "assets/img/gallery/protfolio-img01.jpg",
    "assets/img/gallery/protfolio-img04.jpg",
    "assets/img/gallery/protfolio-img05.jpg",
    "assets/img/gallery/protfolio-img06.jpg",
    "assets/img/gallery/protfolio-img07.jpg",
    "assets/img/gallery/protfolio-img08.jpg",
    "assets/img/gallery/protfolio-img09.jpg",
    "assets/img/gallery/protfolio-img10.jpg",
    "assets/img/gallery/room-img01.jpg",
    "assets/img/gallery/room-img02.jpg",
    "assets/img/gallery/room-img03.jpg",
    "assets/img/gallery/room-img04.jpg",
    "assets/img/gallery/room-img05.jpg",
    "assets/img/gallery/room-img06.jpg",
    "assets/img/gallery/bathroom-img01.jpg",
    "assets/img/gallery/bathroom-img02.jpg",
    "assets/img/gallery/bathroom-img03.jpg",
  ]);

  const [img, setImg] = useState();
  const [img1, setImg1] = useState();
  const [img2, setImg2] = useState();
  const [img3, setImg3] = useState();
  const [img4, setImg4] = useState();
  const [img5, setImg5] = useState();
  const [img6, setImg6] = useState();
  const [img7, setImg7] = useState();
  const [img8, setImg8] = useState();
  const [img9, setImg9] = useState();
  const [img10, setImg10] = useState();
  const [img11, setImg11] = useState();
  const [img12, setImg12] = useState();
  const [img13, setImg13] = useState();
  const [img14, setImg14] = useState();
  const [img15, setImg15] = useState();
  const [img16, setImg16] = useState();
  const [img17, setImg17] = useState();
  const [img18, setImg18] = useState();

  const [toggler, setToggler] = useState(false);

  return (
    <>
      <section className="profile fix pt-15">
        <div className="container-fluid gallery-lay">
          <div className="">
            <div className="masonry-gallery-huge">
              <div className="row ">
                <>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(1);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img02.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>

                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg1(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(2);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img03.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg2(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(3);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img01.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg3(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(4);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img04.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg4(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(5);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img05.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg5(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(6);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img06.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg6(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(7);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img07.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg7(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(8);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img08.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg8(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(9);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img09.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg9(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(10);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/protfolio-img10.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg10(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(11);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/room-img01.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg11(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(12);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/room-img02.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg12(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(13);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/room-img03.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg13(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(14);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/room-img04.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg14(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(15);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/room-img05.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg15(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(16);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/room-img06.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg16(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(17);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/bathroom-img01.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg17(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(18);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/bathroom-img02.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="grid-item">
                    <Link
                      to="#"
                      onClick={() => setImg18(true)}
                      className="popup-image"
                    >
                      <figure
                        className="gallery-image"
                        onClick={() => {
                          setActiveImage(19);
                          setToggler(!toggler);
                        }}
                      >
                        <img
                          src="assets/img/gallery/bathroom-img03.jpg"
                          alt="img"
                          className="img col-sm-6"
                        />
                      </figure>
                    </Link>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FsLightbox toggler={toggler} sources={images} slide={activeImage} />
    </>
  );
}

export default First;
