import React from "react";
// import Bredcom from '../../Bradcom/Main'
import First from "../../Mengallary/First";

const Main = () => {
  return (
    <>
      <main>
        {/* <Bredcom title={"Gallery"} subtitle={"Home"} newtitle={"Gallery"}/> */}
        <First />
      </main>
    </>
  );
};

export default Main;
