import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Main = () => {
  const [mobile, setmobile] = useState(false);
  const [show1, setshow1] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      <header className="header-area header-three">
        <div className="header-top second-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-10 col-md-10 d-none d-lg-block">
                <div className="header-cta">
                  <ul>
                    <li>
                      <i className="far fa-clock"></i>
                      <span>Open 24 hours, 7 days a week</span>
                    </li>
                    <li>
                      <i className="far fa-mobile"></i>
                      <strong>+256 7895 16900</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 d-none d-lg-block text-right">
                <div className="header-social">
                  <span>
                    <a
                      href="https://www.facebook.com/entebbe.seedhotel"
                      title="Facebook"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://instagram.com/seed.hotel?utm_source=qr&igshid=OGIxMTE0OTdkZA=="
                      title="Instagram"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    {/* <Link to="#" title="Twitter"><i className="fab fa-twitter"></i></Link> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-sticky" className="menu-area">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2">
                  <div className="logo">
                    <Link to="/">
                      <h2 style={{ color: "#fff" }}>
                        Seed <span style={{ color: "#0E663D" }}>Hotel</span>
                      </h2>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8">
                  <div className="main-menu text-center">
                    <nav id="mobile-menu ">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/reservation">Reservation</Link>
                        </li>
                        <li>
                          <Link to="/room">Our rooms</Link>
                        </li>
                        <li>
                          <Link to="/gallery">Gallery</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 d-none d-lg-block">
                  <Link to="/reservation" className="top-btn mt-10 mb-10">
                    Book Now{" "}
                  </Link>
                </div>
                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <Link
                        to="#nav"
                        className={`meanmenu-reveal ${mobile && "meanclose"}`}
                        onClick={() =>
                          setmobile(mobile === true ? false : true)
                        }
                        style={{
                          right: "0px",
                          left: "auto",
                          textAlign: "center",
                          textIndent: "0px",
                          fontSize: "18px",
                        }}
                      >
                        {mobile ? (
                          "X"
                        ) : (
                          <>
                            <span>
                              <span>
                                <span></span>
                              </span>
                            </span>
                          </>
                        )}
                      </Link>
                      <nav className="mean-nav">
                        {mobile && (
                          <ul style={{ display: mobile ? "block" : "none" }}>
                            <li>
                              <Link to="/">Home</Link>
                            </li>
                            <li>
                              <Link to="/about">About</Link>
                            </li>
                            <li>
                              <Link to="/reservation">Reservation</Link>
                            </li>
                            <li>
                              <Link to="/room">Our Rooms</Link>
                            </li>
                            <li>
                              <Link to="/gallery">Gallery</Link>
                            </li>
                            <li className="mean-last">
                              <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                        )}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Main;
