import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

function First() {
  const form = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    emailjs
      .sendForm(
        "service_6xo1bba",
        "template_wbm9elo",
        form.current,
        "foQOBRLap4Hjg8rAn"
      )
      .then(
        (result) => {
          Swal.fire({
            title: "Successful",
            text: "Your inquiry has been sent",
            icon: "success",
          });
          e.target.reset();
          setButtonDisabled(false);

        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!. Please try reloading the page and then try again",
            footer: '<Link to="/contact">Contact Us</Link>',
          });
        }
      );
  };
  return (
    <>
      <section
        id="contact"
        className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix"
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-4 order-1">
              <div className="contact-info">
                <div
                  className="single-cta pb-30 mb-30 wow fadeInUp animated"
                  data-animation="fadeInDown animated"
                  data-delay=".2s"
                >
                  <div className="f-cta-icon">
                    <i className="far fa-map"></i>
                  </div>
                  <h5>Address</h5>
                  <p>
                    Along Entebbe Highway <br />
                    behind Stabex Kitooro
                  </p>
                </div>
                <div
                  className="single-cta pb-30 mb-30 wow fadeInUp animated"
                  data-animation="fadeInDown animated"
                  data-delay=".2s"
                >
                  <div className="f-cta-icon">
                    <i className="far fa-clock"></i>
                  </div>
                  <h5>Working Hours</h5>
                  <p>24 hours, 7 day a week</p>
                </div>
                <div
                  className="single-cta wow fadeInUp animated"
                  data-animation="fadeInDown animated"
                  data-delay=".2s"
                >
                  <div className="f-cta-icon">
                    <i className="far fa-envelope-open"></i>
                  </div>
                  <h5>Message Us</h5>
                  <p>
                    {" "}
                    <Link to="#">info@seed-hotel.com</Link>
                    <br />
                    <Link to="#">booking@seed-hotel.com</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 order-2">
              <div className="contact-bg02">
                <div
                  className="section-title center-align mb-40 text-center wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  <h2>Get In Touch</h2>
                </div>
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="contact-form mt-30"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-name mb-20">
                        <input
                          type="text"
                          placeholder="Name"
                          required
                          id="user_name"
                          name="user_name"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="text"
                          placeholder="Email"
                          name="email"
                          id="email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="text"
                          placeholder="Phone No."
                          id="ph_no"
                          name="ph_no"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="text"
                          placeholder="Subject"
                          name="subject"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-message mb-30">
                        <textarea
                          cols="30"
                          rows="10"
                          placeholder="Message"
                          id="message"
                          name="message"
                        ></textarea>
                      </div>
                      <div className="slider-btn">
                        <button disabled={buttonDisabled}
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                          type="submit"
                        >
                          <span>Submit Now</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
