import { React, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Hotel from "../../assets/img/bg/an-img-01.png";
import Booking from "../../assets/img/bg/booking-img.png";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

function Book() {
  const form = useRef();  
  const [buttonDisabled, setButtonDisabled] = useState(false);



  const sendEmail = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    
    emailjs
      .sendForm(
        "service_6xo1bba",
        "template_5n5caqb",
        form.current,
        "foQOBRLap4Hjg8rAn"
      )
      .then(
        (result) => {
          // console.log(result.text);
          Swal.fire({
            title: "Successful",
            text: "Your request has been sent",
            icon: "success",
          });
          e.target.reset();
          setButtonDisabled(false);

        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: '<Link to="/contact">Contact Us</Link>',
          });
        }
      );
  };
  return (
    <>
      <section className="booking pt-120 pb-120 p-relative fix">
        <div className="animations-01">
          <img src={Hotel} alt="an-img-01" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="contact-bg02">
                <div className="section-title center-align">
                  <h5>make a reservation</h5>
                  <h2>Book A Room</h2>
                </div>
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="contact-form mt-30"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-name mb-20">
                        <label>
                          <i className="fal fa-user"></i> First Name
                        </label>
                        <input type="text" id="first_name" name="first_name" />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-user"></i> Last Name
                        </label>
                        <input type="text" id="last_name" name="last_name" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="contact-field p-relative c-option mb-20">
                        <label>
                          <i className="fal fa-concierge-bell"></i> Room
                        </label>
                        <select name="room_type" id="rm2">
                          <option value="Standard">Standard Room</option>
                          <option value="Deluxe">Deluxe Room</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-home"></i> Number of rooms
                        </label>
                        <input
                          type="number"
                          id="room_nos"
                          name="room_nos"
                          value={"1"}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-name mb-20">
                        <label>
                          <i className="fal fa-badge-check"></i> Check In Date
                        </label>
                        <input type="date" id="check_in" name="check_in" />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-times-octagon"></i> Check Out
                          Date
                        </label>
                        <input type="date" id="check_out" name="check_out" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-envelope"></i> Email
                        </label>
                        <input type="email" id="email" name="email" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-phone"></i> Phone Number
                        </label>
                        <input type="text" id="ph_no" name="ph_no" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-check"></i> Other Information (If
                          any)
                        </label>
                        <textarea id="other" name="other" />
                      </div>
                    </div>
                    <div className="terms">
                      <p>
                        Proceeding confirms that you agree to the{" "}
                        <Link style={{ color: "green" }} to="/terms">
                          terms and condition
                        </Link>
                      </p>
                    </div>
                    <div className="col-lg-12">
                      <div className="slider-btn mt-15">
                        <button disabled={buttonDisabled}
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                        >
                          <span>Book Now</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="booking-img">
                <img src={Booking} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Book;
