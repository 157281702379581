import React from "react";
import { Link } from "react-router-dom";
import About from "../../assets/img/bg/an-img-02.png";
import About1 from "../../assets/img/features/about_img_02.png";

function Most() {
  return (
    <>
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
        <div className="animations-02">
          <img src={About} alt="contact-bg-an-02" />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={About1} alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content  wow fadeInRight  animated pl-30"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>About Us</h5>
                  <h2>Allow us to share a glimpse into who we are.</h2>
                </div>
                <p>
                  Embrace the convenience of our round-the-clock operations at
                  the reception and restaurant, ensuring your needs are met at
                  any hour. We go the extra mile for our guests, offering
                  complimentary airport pickups, seamless wireless internet
                  connectivity, and efficient laundry services.
                </p>
                <p>
                  Our commitment extends to providing dedicated support for late
                  arrivals and early departures, ensuring a hassle-free
                  experience. Immerse yourself in a world of service where every
                  detail is meticulously handled, and our attentive staff is
                  ready to cater to your needs. From arrival to departure, we
                  strive to make your stay with us a seamless and enjoyable
                  experience.
                </p>
                <div className="about-content3 mt-30">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul className="green mb-30">
                        <li>
                          {" "}
                          24/7 operations at the reception and restaurant.{" "}
                        </li>
                        <li> Complimentary airport pickups. </li>
                        <li>
                          {" "}
                          Dedicated support for late arrivals and early
                          departures.{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <div className="slider-btn">
                        <Link to="/about" className="btn ss-btn smoth-scroll">
                          Discover More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Most;
