import React from "react";
import { Link } from "react-router-dom";
import BGImg from "../../assets/img/bg/footer-bg.png";

const Main = () => {
  return (
    <>
      <footer className="footer-bg footer-p">
        <div
          className="footer-top  pt-90 pb-40"
          style={{
            background_color: "#0E663D",
            backgroundImage: `url(${BGImg})`,
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title mb-30">
                    <img src="assets/img/logo/logo.png" alt="img" />
                  </div>
                  <div className="f-contact">
                    <ul>
                      <li>
                        <i className="icon fal fa-phone"></i>
                        <span>
                          256 7895 16900 {/* <br /> */}
                          {/* +256 7040 58769 */}
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-envelope"></i>
                        <span>
                          <Link to="#">info@seed-hotel.com</Link>
                          <br />
                          <Link to="#">booking@seed-hotel.com</Link>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-map-marker-check"></i>
                        <span>
                          Plot 15 Akiiki Nyambongo Road Kitoro-Entebbe Behind
                          Stabex Kitoro
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Our Links</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about"> About Us</Link>
                      </li>
                      <li>
                        <Link to="/room"> Rooms </Link>
                      </li>
                      <li>
                        <Link to="/reservation"> Reservation </Link>
                      </li>
                      <li>
                        <Link to="/gallery"> Gallery </Link>
                      </li>
                      <li>
                        <Link to="/contact"> Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>For Inquiries</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/contact">Support</Link>
                      </li>
                      <li>
                        <Link to="/policies">Privacy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Term & Conditions</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                Copyright &copy; SEED HOTEL 2023 . All rights reserved.
              </div>
              <div className="col-lg-6 col-md-6 text-right text-xl-right">
                <div className="footer-social">
                  <a
                    target="blank"
                    href="https://www.facebook.com/entebbe.seedhotel"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                  <a
                    target="blank"
                    href="https://instagram.com/seed.hotel?utm_source=qr&igshid=OGIxMTE0OTdkZA=="
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Main;
