import { Route, Routes } from "react-router-dom";
import Navbar from "../component/Navbar/Main";
import Footer from "../component/Footer/Main";
import Home from "../component/Home/Main";
import About from "../component/About/Main";
import OurRoom from "../component/Rooms/OurRoom/Main";
import Reservation from "../component/Reservation/Main";
import Gallery from "../component/Pages/Gallary/Main";
import Contact from "../component/Contact/Main";
import Policies from "../component/Conditions/Policies";
import Terms from "../component/Conditions/Terms";

const Routing = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/room" element={<OurRoom />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/policies" element={<Policies />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Routing;
