import React, { useState } from "react";
import { Link } from "react-router-dom";
import BGVideo from "../../assets/img/bg/video-bg.png";
import Play from "../../assets/img/bg/play-button.png";

function Take() {
  const [yShow, setyShow] = useState();
  const [hShow, sethShow] = useState();

  return (
    <>
      <section
        id="video"
        className="video-area pt-150 pb-150 p-relative"
        style={{
          backgroundImage: `url(${BGVideo})`,
          background_repeat: "no-repeat",
          background_position: "bottom",
          background_size: "cover",
        }}
      >
        <div className="content-lines-wrapper2">
          <div className="content-lines-inner2">
            <div className="content-lines2"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="s-video-wrap">
                <div className="s-video-content">
                  <Link
                    to="#"
                    className="popup-video"
                    onClick={() => setyShow(true)}
                  >
                    <img src={Play} alt="circle_right" />
                  </Link>
                </div>
              </div>
              <div className="section-title center-align text-center">
                <h2>Take A Tour</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {yShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: "hidden" }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => setyShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    className="mfp-iframe"
                    src="assets/img/video/tour.mp4"
                    frameborder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}

      {hShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: "hidden" }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => sethShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    className="mfp-iframe"
                    src="assets/img/video/tour.mp4"
                    frameborder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Take;
